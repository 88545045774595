import React from 'react'
// import {FacebookShareButton, FacebookIcon} from "react-share";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    OKShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
      } from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    OKIcon,
    TelegramIcon,
    TwitterIcon,
     WhatsappIcon,
   } from "react-share";

export default function SocialMediaButtons({url,text}) {
       return (<div>
           <EmailShareButton url={url} quote={text} title={text} >
                 <EmailIcon size={25} round={true} />
              </EmailShareButton>
              
              <FacebookShareButton url={url} quote={text} title={text} >
                 <FacebookIcon size={25} round={true} />
              </FacebookShareButton>
              
              
              <TwitterShareButton url={url} quote={text} title={text} >
                 <TwitterIcon size={25} round={true} />
              </TwitterShareButton>
              
              <WhatsappShareButton url={url} quote={text} title={text} >
                 <WhatsappIcon size={25} round={true} />
              </WhatsappShareButton>
              
              <LinkedinShareButton url={url} quote={text} title={text} >
                 <LinkedinIcon size={25} round={true} />
              </LinkedinShareButton>
              
              <TelegramShareButton url={url} quote={text} title={text} >
                 <TelegramIcon size={25} round={true} />
              </TelegramShareButton>
              
              <OKShareButton url={url} quote={text} title={text} >
                 <OKIcon size={25} round={true} />
              </OKShareButton>
              
              </div>
             
       );
}

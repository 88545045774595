import React, {  useEffect, useState} from 'react';
// import { EditorState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Button from '@material-ui/core/Button';
// import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import FileUploadPage from 'dugoly-uploader'
import htmlToDraft from 'html-to-draftjs';

// כשאין נתוני פוסט - המשמעות היא שזה כתיבת פוסט חדש , אחרת זה עריכה

const TextEditords  =({EditordText, setEditordText})=>{
// class TextEditords extends Component
 {

  // state = {
  //   editorState: EditorState.createEmpty(),
  // }

  const onEditorStateChange = (editorState) => {console.log("editorState:  ",editorState)
    // this.setState({
    //   editorState,
    setEditordText(editorState)
    // })
  }

  // render() {
  //   const { editorState } = this.state;
    // console.log(editorState.getCurrentContent());
    return (
      <div>
        <div style={{ backgroundColor: "white", minHeight: "350px",boxSizing:"border-box", }}>
          <Editor
            editorStyle={{
              minHeight:"350px",
              boxSizing:"border-box",
            }}
            editorState={EditordText}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </div>
      </div>
    );
  }
}

function TextFiled({Texts, setTexts, EditordText, setEditordText}) {
  
  

  const onDataChange=(e)=>{
    
switch(e.target.className){
  case("titleInput"):
  setTexts({...Texts,Title:e.target.value})
  break;

  case("summaryInput"):
  setTexts({...Texts,Summary:e.target.value})
  break;

  // case("textInput"):
  // setTexts({...Texts,Text:e.target.value})
  // break;

}
  }
  return (
    <div 
    style={{
      boxSizing:"border-box",
            padding:"0px",
    }}>
      <div className="textinput" style={{
        maxWidth: "100%",
        minWidth: "60%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        boxSizing:"border-box",
        alignItems: "flex-start",
        backgroundColor: "transparent",
        padding: "20px 10px 20px 10px"
      }}>
        <input
          type="text"
          className="titleInput"
          placeholder="Enter Blog Title Here"
          onChange={onDataChange}
          value={Texts.Title}
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "#ffffff",
            color: "gray",
            boxSizing:"border-box",
            fontFamily: 'Segoe UI',
            fontSize: "12px",
            margin: "0px 0px 30px 0px",
            padding: "16px"
          }}
        />
        <input type="text" className="summaryInput" placeholder="Blog Summary" 
        onChange={onDataChange}
        value={Texts.Summary}
        style={{
          width: "100%",
          border: "none",
          outline: "none",
          backgroundColor: "#ffffff",
          color: "gray",
          fontFamily: 'Segoe UI',
          fontSize: "12px",
          boxSizing:"border-box",
          margin: "0px 0px 30px 0px",
          padding: "16px"
        }} />
        {/* <button className="addmediabtn" style={{
          maxWidth: "200px",
          width: "100%",
          minWidth: "0px",
          textAlign: "center",
          boxSizing:"border-box",
          padding: "10px 0px",
          color: "#5381ff",
          border: "none",
          outline: "none",
          backgroundColor: "white",
          fontSize: "14px",
          fontFamily: 'Segoe UI',
          margin: "0px 0px 30px 0px"
        }}>Add Media</button> */}
        <div style={{ width: "100%", maxWidth: "900px" ,boxSizing:"border-box",}}>
          <TextEditords EditordText={EditordText} setEditordText={setEditordText}/>
          {/* {console.log (document.querySelector(".editorClassName"))} */}
        </div>
      </div>
    </div>
  );
}

// function SelectionField() {
//   return (
//     <div>
//       <div className="selection" style={{
//         maxWidth: "380px",
//         width: "100%",
//         boxSizing:"border-box",
//         backgroundColor: "transparent",
//         padding: "20px 20px 20px 20px",
//       }}>
//         {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר-------- */}
//         {/* <div className="displayTo" style={{
//           width: "100%",
//           boxSizing:"border-box",
//           margin: "0px 0px 30px 0px"
//         }}>
//           <p className="subtitle" style={{
//             width: "100%",
//             color: "#5381ff",
//             fontFamily: 'Segoe UI',
//             fontSize: "16px",
//             boxSizing:"border-box",
//             margin: "0px 0px 24px 0px"
//           }}>Display To</p>
//           <div className="userselect" style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//             boxSizing:"border-box",
//             alignItems: "center"
//           }}>
//             <div className="selected" style={{
//               width: "49%",
//               border: "none",
//               outline: "none",
//               fontSize: "20px",
//               color: "#28292d",
//               backgroundColor: "white",
//               boxSizing:"border-box",
//               padding: "10px 0px",
//               textAlign: "center"
//             }}>All users</div>
//             <select className="selected" style={{
//               width: "49%",
//               border: "none",
//               outline: "none",
//               boxSizing:"border-box",
//               fontSize: "20px",
//               color: "#28292d",
//               backgroundColor: "white",
//               padding: "10px 0px",
//               textAlign: "center"
//             }}>
//               <option selected="true"></option>
//               <option>ldasmc</option>
//               <option>ldasmc</option>
//               <option>ldasmc</option>
//             </select>
//           </div>
//         </div> */}
//         {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר-------- */}
//         <div className="Insertimg" style={{
//           width: "100%",
//           boxSizing:"border-box",
//           margin: "0px 0px 30px 0px"
//         }}>
//           <p className="subtitle" style={{
//             width: "100%",
//             color: "#5381ff",
//             fontFamily: 'Segoe UI',
//             fontSize: "16px",
//             boxSizing:"border-box",
//             margin: "0px 0px 24px 0px"
//           }}>Insert Image</p>
//           <img
//             className="imagse"
//             src="http://www.easydone.online/uploads/fls/8391ea6b-c196-4fbe-a437-2594928f13f3.jpeg"
//             alt=""
//             style={{
//               width: "140px",
//               height: "140px",
//               boxSizing:"border-box",
//               objectFit: "scale-down",
//               margin: "10px 0px",
//               display: "block",
//             }}
//           />
//           <div className="btns" style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "row",
//             boxSizing:"border-box",
//             justifyContent: "space-between",
//             alignItems: "center",
//             flexWrap: "wrap"
//           }}>
//             <Button className="imgbtn" style={{
//               width: "40%",
//               minWidth: "100px",
//               backgroundColor: "#5381ff",
//               boxSizing:"border-box",
//               textTransform:"capitalize",
//               color: "white",
//               textAlign: "center",
//               fontWeight:"100",
//               fontSize: "14px",
//               padding: "10px 0px",
//               border: "none",
//               outline: "none",
//               marginBottom: "10px"
//             }}>Add Image</Button>
//             {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר------- */}
//             {/* <Button className="imgbtn" style={{
//               width: "40%",
//               minWidth: "100px",
//               backgroundColor: "#5381ff",
//               boxSizing:"border-box",
//               textTransform:"capitalize",
//               fontWeight:"100",
//               color: "white",
//               textAlign: "center",
//               fontSize: "14px",
//               padding: "10px 0px",
//               border: "none",
//               outline: "none",
//               marginBottom: "10px"
//             }} >Delete Image</Button> */}
//           {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר---------- */}
//           </div>
//         </div>
//         <div className="Insertimg" style={{
//           width: "100%",
//           boxSizing:"border-box",
//           margin: "0px 0px 30px 0px"
//         }}>
//           {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר---------- */}
//           {/* <p className="subtitle" style={{
//             width: "100%",
//             color: "#5381ff",
//             fontFamily: 'Segoe UI',
//             boxSizing:"border-box",
//             fontSize: "16px",
//             margin: "0px 0px 24px 0px"
//           }}>Add Tags</p>
//           <input type="text" className="textInput" placeholder="Write Tags" style={{
//             width: "100%",
//             border: "none",
//             outline: "none",
//             backgroundColor: "#ffffff",
//             color: "gray",
//             fontFamily: 'Segoe UI',
//             boxSizing:"border-box",
//             fontSize: "12px",
//             margin: "0px 0px 30px 0px",
//             padding: "16px"
//           }} />
//           <Button className="addbtn" style={{
//             backgroundColor: "#6dbc97",
//             color: "white",
//             padding: "10px 20px",
//             fontWeight:"100",
//             textTransform:"capitalize",
//             textAlign: "center",
//             fontSize: "16px",
//             boxSizing:"border-box",
//             border: "none",
//             outline: "none"
//           }} >Add</Button> */}

//           {/* -------------קטעים אלו מיועדים לשלבים מתקדמים יותר---------- */}

//         </div>
//       </div>
//     </div>
//   );
// }


const savePost=(uid,BCode, post, setComponent, postId, updateData)=>{
  let apiPath=postId?"https://dugoly.com/api/editPost":"https://dugoly.com/api/addPost"
  // let apiPath=postId?"http://localhost:3002/api/editPost":"http://localhost:3002/api/addPost"
console.log("postId:  ",postId)
console.log("apiPath:  ",apiPath)
const cookies=(document.cookie)
console.log("Document.cookie:  ",document.cookie)
console.log("decodeURIComponent>Document.cookie:  ",cookies)
console.log("Document.cookie>tostring:  ",cookies.toString)
  const domain="dugoly.com"
    fetch(apiPath, {
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify({    
      domain, uid, BCode, post, postId, cookies
    })
  })
    .then(response => response.json())
    .then(data => {
  console.log("data:  ",data)
  if(data===1005){
    updateData()
    alert(post?"The data has been saved, you can continue editing by clicking on the edit button, in the post view":"the post was deleted")
  setComponent("main")}
else{alert("Data save error, please try again later")}
 })
}

function AddPost({setComponent, owner, postData, ownerUid, bCode, dir, updateData}) {
  if(!(owner))return(<div>this page is only for the owner!</div>)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  // const [Title, setTitle]= useState("")
  // const [Summary , setSummary ]= useState()
  const [EditordText, setEditordText]= useState(EditorState.createEmpty())
const [Texts, setTexts]=useState({})
const [ImgUrl,setImgUrl]=useState()
let postId
console.log("EditordText:  ",EditordText)
console.log("Texts:  ",Texts)
if(postData){
  postId=postData.postId
  console.log("post data:  ",postData)
  useEffect(()=>{
  const contentBlock = htmlToDraft(postData.text);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
  setEditordText(editorState)
    }
  setTexts({Title:postData.title, Summary:postData.summary})
  setImgUrl(postData.imageUrl)
  
  },[])


}

function Header({setComponent, postData}) {
  
  return (
    <div style={{
      boxSizing:"border-box",
      padding:"0px",
    }}>
      <div className="header" style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        boxSizing:"border-box",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "20px 0px 18px 0px",
        flexWrap: "wrap"
      }}>
        <div className="headerTitlt" style={{
          fontFamily: 'Segoe UI',
          fontWeight: "bold",
          color: "#28292d",
          fontSize: "26px",
          boxSizing:"border-box",
          width: "100%",
          maxWidth: "500px",
          textAlign: "left"
        }}> <Button 
        onClick={()=>{setComponent("main")}}
        style={{
          border:"none",
          outline:"none",
          backgroundColor:"transparent",
          fontWeight:"100",
          boxSizing:"border-box",
          textTransform:"capitalize",
          fontSize:"30px"
        }} >&#10094; </Button>  {postData?"edit artical":"Add Artical"}</div>
        <div className="headerButtos" style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          boxSizing:"border-box",
          alignItems: "center",
          width: "100%",
          maxWidth: "580px",
          flexWrap: "wrap"
        }}>
          <Button className="publishbtn" 
          onClick={()=>{savePost(ownerUid,
                                  bCode,
                                  {title:Texts.Title, text:draftToHtml(convertToRaw(EditordText.getCurrentContent())), summary:Texts.Summary, imageUrl:ImgUrl},
                                  setComponent,
                                  postId,
                                  updateData)}}
          style={{
            width: "30%",
            textAlign: "center",
            padding: "5px 0px",
            backgroundColor: "#6c77ff",
            textTransform:"capitalize",
            boxSizing:"border-box",
            color: "white",
            fontWeight:"200",
            fontSize: "16px",
            fontFamily: 'Segoe UI',
            borderRadius: "6px",
            border: "none",
            outline: "none",
            minWidth: "100px",
          }}>Publish</Button>
          <Button className="cancelbtn" 
          onClick={()=>{setComponent("main")}}
          style={{
            width: "30%",
            textAlign: "center",
            boxSizing:"border-box",
            padding: "5px 0px",
            fontWeight:"200",
            backgroundColor: "transparent",
            textTransform:"capitalize",
            color: "#6c77ff",
            fontSize: "16px",
            fontFamily: 'Segoe UI',
            borderRadius: "6px",
            border: "1px solid #6c77ff",
            outline: "none",
            minWidth: "100px",
          }} >Cancel</Button>
          {postData&&<Button className="deletebtn" 
          onClick={()=>{savePost(ownerUid, bCode, null,setComponent, postId, updateData)}}
          style={{
            width: "30%",
            textAlign: "center",
            padding: "5px 0px",
            boxSizing:"border-box",
            backgroundColor:"#fb4c4c",
            textTransform:"capitalize",
            color: "white",
            fontSize: "16px",
            fontFamily: 'Segoe UI',
            fontWeight:"200",
            borderRadius: "6px",
            border: "none",
            outline: "none",
            minWidth: "100px",
          }}>Delete Post</Button>}
        </div>
      </div>
    </div>
  );
}


  return (
    <div 
    dir={dir}
    style={{
      width: document.activeElement.clientWidth<=240 ? "96%" : "100%" ,
      margin:"auto",
      boxSizing:"border-box",
      padding:"0px",
  }}>
      <Header setComponent={setComponent} postData={postData} />
      <div className="postaddform" style={{
        display: "flex",
        flexDirection: "row",
        boxSizing:"border-box",
        justifyContent: "space-around",
        alignItems: "flex-start",
        backgroundColor: "#f6f6f6",
        padding: "24px 0px 0px 0px",
        flexWrap: "wrap"
      }}>
        {/* <TextFiled setTitle={setTitle} setSummary={setSummary} setText={setText}/> */}
        <TextFiled Texts={Texts} setTexts={setTexts} EditordText={EditordText} setEditordText={setEditordText}/>
        <div>
        {postData&&(<div>You can upload a picture<br/>The new image will replace the previous image!<br/></div>) }        
        <FileUploadPage callback={setImgUrl} />
      </div>
      </div>
    </div>
  );
}

export default AddPost;

import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import ClipLoader from "react-spinners/ClipLoader";

function FileUploadPage({doAfter=()=>{}, doOnError=()=>{} }){
	
	const [ImgUrl,setImgUrl]=useState()
const [Uploading,setUploading]=useState(false)

	// Create a reference to the hidden file input element
	const hiddenFileInput = React.useRef(null);
  
	// Programatically click the hidden file input element
	// when the Button component is clicked
	const handleClick = event => {
    if(!getCookie(document.cookie,"uid")||!getCookie(document.cookie,"dugolyToken")){alert("you have to login before uploading")}
else{
	  hiddenFileInput.current.click();
}
	};

	const changeHandler = (event) => {
		const fileData=event.target.files[0]
		setUploading(true)
		const formData = new FormData();
const uid=getCookie(document.cookie,"uid")
const token=getCookie(document.cookie,"dugolyToken")

		formData.append("uid", uid);
		formData.append("token", token);
		formData.append("photo", fileData);
let responseStatus=2222
		fetch(
			// 'http://www.localhost:3000/upload',
			'https://www.easydone.online/upload',
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => {
        responseStatus=response.status
        return response.json()})
			.then((result) => {
        if((responseStatus>199)&&(responseStatus<300)){
				setImgUrl(result)
				doAfter(result)}
        else{doOnError("response status is:  ",responseStatus)}
        
        setUploading(false)
			})
			.catch((error) => {
				console.error('Error 1:', error);
        setUploading(false)
        doOnError(error)
			}).catch((error) => {
				console.error('Error 2:', error);
        setUploading(false)
        doOnError(error)
			});
  };
	

	return(
   <div>
{Uploading?(<div>
	<ClipLoader  loading={true}  size={50} />
	<br/>
	Uploading data, please wait
</div>): (<div className="Insertimg" style={{
          width: "100%",
          boxSizing:"border-box",
          margin: "0px 0px 30px 0px"
        }}>
         {!ImgUrl&&<p className="subtitle" style={{
            width: "100%",
            color: "#5381ff",
            fontFamily: 'Segoe UI',
            fontSize: "16px",
            boxSizing:"border-box",
            margin: "0px 0px 24px 0px"
          }}>Insert Image</p>}
          {ImgUrl&&<img
            className="imagse"
            src={ImgUrl}
            alt=""
            style={{
              width: "140px",
              height: "140px",
              boxSizing:"border-box",
              objectFit: "scale-down",
              margin: "10px 0px",
              display: "block",
            }}
          />}
          {!ImgUrl&&<div className="btns" style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            boxSizing:"border-box",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap"
          }}>
            <Button className="imgbtn" 
			onClick={handleClick}
			style={{
              width: "40%",
              minWidth: "100px",
              backgroundColor: "#5381ff",
              boxSizing:"border-box",
              textTransform:"capitalize",
              color: "white",
              textAlign: "center",
              fontWeight:"100",
              fontSize: "14px",
              padding: "10px 0px",
              border: "none",
              outline: "none",
              marginBottom: "10px"
            }}>Add Image</Button>
			 </div>}
       
	   {/* ------------------------- */}
			<input type="file" name="file" onChange={changeHandler}
			ref={hiddenFileInput} 
			style={{display: 'none'}}
			/> </div>)}
			
</div>
	)
}

function getCookie(cookies,cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(cookies);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}


export default FileUploadPage
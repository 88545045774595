import React, {useState} from 'react'


const ComponentMultiplier=({ComponentToMultiply, ComponentProps, AbsoluteNumber, index=true, generalContainerStyle,  componentContainerStyle})=>{
const [Counter,setCounter]=useState(AbsoluteNumber?AbsoluteNumber:1)


generalContainerStyle=generalContainerStyle?generalContainerStyle: {border:"solid 4px"  , backgroundColor: "#EEEEEE"}
componentContainerStyle=componentContainerStyle?componentContainerStyle:{border:"solid 1px"  , backgroundColor: "#EEE3EE"}

     
    return(
    <div style={generalContainerStyle} >
   {!AbsoluteNumber&&<div> <center>  
 <button onClick={()=>{if (Counter>19){alert("Maximum 20 components")}else{setCounter(Counter+1)}}}>+</button>
 <button onClick={()=>{if (Counter>1){setCounter(Counter-1)}}}>-</button>
 </center></div>}
 { Array.from({ length: Counter }, (_, i) =>
    <i key={i} >
        <div style={componentContainerStyle}>
            {index&&i+1}
    <ComponentToMultiply {...ComponentProps} i={i} />
      
    <br/><br/></div>{!AbsoluteNumber&&"to See more click +"}<br/></i>) }
  </div>)
}

export default ComponentMultiplier